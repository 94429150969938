import EVENT from '../enums/event';

const classes = {
  hide: '!-translate-y-[100%]',
  show: '-translate-y-[var(--space-xl)]',
  fixed: '!fixed',
  absolute: 'absolute',
  bg_solid: '!bg-brand-white',
  hero: '.hero-banner',
  position_fixed: 'lg:fixed',
  position_absolute: 'lg:absolute',
  opacity0: 'opacity-0',
  PointerEventsNone: 'pointer-events-none'
};

const selectors = {
  siteHeader: '#shopify-section-site-header',
  search: '[data-header-search]',
  siteHeaderSearch: '[data-site-header-search]',
  siteHeaderSearchClose: '[data-site-header-search-close]',
  searchInput: '[data-header-search-input]',
  megaMenu: '[data-mega-menu]',
  menu: '[data-header-menu]',
  mobile_menu: '[data-open-mobile-menu]',
  bag: '[data-header-bag]',
  logo: '[data-header-logo]',
  background: '[data-header-background]',
};

class StickyHeader {
  constructor(shopifyHeader) {
    this.shopifyHeader = shopifyHeader;
    this.currentScrollPos = window.pageYOffset;
    this.prevScrollpos = window.pageYOffset;
    this.headerSearch = document.querySelector(selectors.search) ?? false;
    this.siteHeaderSearch = document.querySelector(selectors.siteHeaderSearch) ?? false;
    this.siteHeaderSearchClose = document.querySelector(selectors.siteHeaderSearchClose) ?? false;
    this.headerSearchInput = document.querySelector(selectors.searchInput) ?? false;
    this.menu = document.querySelector(selectors.menu) ?? false;
    this.megaMenu = document.querySelector(selectors.megaMenu) ?? false;
    this.mobileMenu = document.querySelector(selectors.mobile_menu) ?? false;
    this.bag = document.querySelector(selectors.bag) ?? false;
    this.logo = document.querySelector(selectors.logo) ?? false;
    this.headerBackground = document.querySelector(selectors.background) ?? false;
    this.hasHero = document.querySelector(classes.hero) ?? false;

    this.init();

    window.addEventListener('resize', () => {
      this.init();
    });

    window.addEventListener(EVENT.HEADER.WHITE, () => {
      this.setHeaderWhite();
    });
  }

  init() {
    if (this.hasHero) {
      this.shopifyHeader.classList.add(classes.absolute);
      window.addEventListener('scroll', this.scrollHandler);
      this.hoverHandler();

      this.headerBackground.classList.add(classes.opacity0);
      this.switchToWhite('add');
    } else {
      this.headerBackground.classList.remove(classes.opacity0);
    }

    this.hoverHandler();
    this.handleSearch();
  }

  shouldAddCss() {
    return (
      this.prevScrollpos > this.currentScrollPos && this.currentScrollPos !== 0
    );
  }

  atTop() {
    return this.currentScrollPos === 0;
  }

  addCss() {
    this.shopifyHeader.style.top = 0;
    this.shopifyHeader.classList.remove(classes.hide);
    this.shopifyHeader.classList.add(classes.fixed, classes.show);
    this.megaMenu.classList.add(classes.fixed, classes.show);
    this.headerBackground.classList.remove(classes.opacity0);
    this.headerBackground.classList.add(classes.bg_solid);
    this.switchToWhite('remove');
  }

  removeCss() {
    this.shopifyHeader.classList.remove(classes.hide, classes.show, classes.fixed);
    this.megaMenu.classList.remove(classes.hide, classes.show, classes.fixed);
    this.headerBackground.classList.remove(classes.bg_solid);
    this.headerBackground.classList.add(classes.opacity0);
    this.init();
  }

  switchToWhite(toggle) {
    if (this.menu) this.menu.classList[toggle]('!text-brand-white');
    if (this.mobileMenu) this.mobileMenu.classList[toggle]('!fill-brand-white');
    if (this.bag) this.bag.classList[toggle]('!fill-brand-white');
    if (this.logo) this.logo.classList[toggle]('!fill-brand-white');
    if (this.headerSearch)
      this.headerSearch.classList[toggle](
        '!fill-brand-white',
        '!text-brand-white'
      );
    if (this.headerSearchInput)
      this.headerSearchInput.classList[toggle]('placeholder:!text-brand-white');
    }

  hoverHandler() {
    if (!this.hasHero) return;

    [this.shopifyHeader, this.megaMenu].forEach(area => {
      area.addEventListener('mouseover', () => {
        this.setHeaderWhite();
      });
    });

    [this.shopifyHeader, this.megaMenu].forEach(area => {
      area.addEventListener('mouseleave', () => {
        this.unsetHeaderWhite();
      });
    });


    // this.shopifyHeader.addEventListener('mouseleave', () => {
    //   if (this.atTop()) {
    //     this.unsetHeaderWhite();
    //   }
    // });

    this.megaMenu.addEventListener('transitionend', () => {
      if (this.megaMenu.clientHeight === 0 && this.atTop()) {
        this.unsetHeaderWhite();
      }
    });
  }
  

  setHeaderWhite() {
    this.switchToWhite('remove');
    this.headerBackground.classList.remove(classes.opacity0);
  }

  unsetHeaderWhite() {
    this.switchToWhite('add');
    this.headerBackground.classList.add(classes.opacity0);
  }

  shouldHideHeader() {
    return this.currentScrollPos && this.currentScrollPos !== 0;
  }

  hideHeader() {
    this.shopifyHeader.classList.add(classes.hide);
  }

  // positionFixedNav() {
  //   this.megaMenu.classList.remove(classes.position_absolute);
  //   this.megaMenu.classList.add(classes.position_fixed);
  // }

  // positionAbsoluteNav() {
  //   this.megaMenu.classList.remove(classes.position_fixed);
  //   this.megaMenu.classList.add(classes.position_absolute);
  // }

  // scrollHandler = () => {
  //   this.currentScrollPos = window.pageYOffset;

  //   if (this.shouldAddCss()) {
  //     this.addCss();
  //   } else if (this.atTop()) {
  //     this.removeCss();
  //    // this.positionAbsoluteNav();
  //   } else if (this.shouldHideHeader()) {
  //     this.hideHeader();
  //    // this.positionFixedNav();
  //   }

  //   this.prevScrollpos = this.currentScrollPos;
  // };

  handleSearch () {
    this.headerSearch.addEventListener('click', ()=> {
      this.siteHeaderSearch.classList.remove(classes.PointerEventsNone, classes.opacity0);
      this.headerSearchInput.focus();
    })

    this.siteHeaderSearchClose.addEventListener('click', ()=> {
      this.siteHeaderSearch.classList.add(classes.PointerEventsNone, classes.opacity0);
      this.headerSearchInput.value = '';
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new StickyHeader(document.querySelector('#shopify-section-site-header'));
});

export default StickyHeader;
